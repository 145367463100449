import React, { useEffect, useState } from "react";
import Control from 'react-leaflet-custom-control';
import ReactDOMServer from 'react-dom/server';
import {MapContainer, Marker, GeoJSON, ScaleControl, useMap} from 'react-leaflet';
import { useTranslation } from "react-i18next";
import { LeafletMapConfig } from "../../../manager/map/config";
import { ReactComponent as SvgQuestion } from "./img/question.svg";
import { ReactComponent as SvgHome } from "./img/home.svg";
import MarkerIcon from "./img/marker-icon.png";
import NetworkIcon from "./img/reseau.png";
import LegendConform from "./img/legend_conform.png";
import "./leaflet.scss";
import "leaflet/dist/leaflet.css";
import L, {LatLngLiteral} from "leaflet";
import LeafletRuler from "./ruler/LeafletRuler";
import { connect } from "react-redux";
import {MapActions, ReportActions, SearchActions, UserActions} from "../../../store/redux/actions";
import { updateVisibleLayers, setMapPreferences } from "../../../store/redux/actions/user";
import Request, { HTTPMethods }  from "../../../services/Request";
import "./tree/L.Control.Layers.Tree";
import "./tree/L.Control.Layers.Tree.d.ts";
import "./tree/L.Control.Layers.Tree.css";
import { categories } from "../../../config/constants";
import { ReactComponent as CrossSvg } from "../../../assets/img/icons/cross.svg";
import { ReactComponent as CircleBlue } from "../../../assets/img/circle-blue.svg";
import { ReactComponent as CircleBlack } from "../../../assets/img/circle-black.svg";
import { ReactComponent as CircleBlackOutlineGreen } from "../../../assets/img/circle-black-outline-green.svg";
import { ReactComponent as CircleBlackOutlineRed } from "../../../assets/img/circle-black-outline-red.svg";
import { wfsIcons } from "./patrimoineIcons";
import {
    conformityLayers,
    wfsPatrimoineLayers,
    mixPatrimoineLayers,
    geoserver1Layers,
    geoserver2Layers,
    geoserver3Layers,
    geoserver4Layers
} from "./layers";
import {GetBounds} from "./functions/getBounds";
import {ResizeMap} from "./functions/resizeMap";
import {LocateComponent} from "./functions/locateComponent";
import {AssetLayer, LayerObject, GroupObject, ILeafletMapComponent, HighlightInterface} from "./types";

function mathCeilFloorWithRoundingPrecision(value: number, operation = 'floor', valIncrement = 0.01) {
    if ('ceil' === operation) {
        return Math.ceil(value / valIncrement) * valIncrement;
    } else {
        return Math.floor(value / valIncrement) * valIncrement;
    }
}

const LeafletMapComponent = (props: ILeafletMapComponent) => {
    const [map, setMap] = useState<any|null>(null);
    const [doZoom, setDoZoom] = useState<boolean>(false);
    const [doStateShowDetail, setDoStateShowDetail] = useState<boolean>(true);
    const [pref, setPref] = useState<any>(null);
    let defaultSelectedLayers: any = [];
    let comLayers: any = [];
    let intercomLayers: any = [];
    let reseauEuLayers: any = [];
    let reseauEpLayers: any = [];
    let reseauUnLayers: any = [];
    let reseauInconnuLayers: any = [];
    const { children, zooms, mapInfo, contract, search,
        setMapInfo, showDetail, showDetailControl, getMapPreferences, showReport, hideReport,
        fly3dToPosition, ...rest } = props;
    let center = props.center;
    if (mapInfo.zoom !== 0) {
        zooms.zoom = mapInfo.zoom;
    }
    if (mapInfo.center !== null) {
        center = [mapInfo.center.lat, mapInfo.center.lng]
    }

    const contractId = contract.id;
    const assetLayers = contract.assetLayers;
    let assetLayersWithKey: any = {};
    Object.keys(assetLayers).map((key: any) => {
        let layerKey = assetLayers[key]['layer'];
        assetLayersWithKey[layerKey] = assetLayers[key];
    });
    const baseLayers = contract.baseLayers;
    let patrimoineLayers: string[] = [];
    assetLayers.map((layer: AssetLayer) => {
        if ('Patrimoine' === layer['group']) {
            patrimoineLayers.push(layer['layer']);
        }
    });

    const { t } = useTranslation();
    const [showLegend, setShowLegend] = useState(false);

    // System of showing all sublayers of filter MO = com/intercom (For client siahvy)
    // Configuration in heimdall contract_metadata moFilter
    const moFilter = contract.contractMetadatas?.moFilter === "1";
    const [showFilterMo, setShowFilterMo] = useState(false);
    const [comActive, setComActive] = useState(false);
    const [intercomActive, setIntercomActive] = useState(false);
    const [reseauEuActive, setReseauEuActive] = useState(false);
    const [reseauEpActive, setReseauEpActive] = useState(false);
    const [reseauUnActive, setReseauUnActive] = useState(false);
    const [reseauInconnuActive, setReseauIncunnuActive] = useState(false);

    const [showHighlight, setShowHighlight] = useState(false);
    const [highlightPoint, setHighlightPoint] = useState<any>(false);
    const [highlightCircle, setHighlightCircle] = useState<any>(false);
    // const [getFeature, setGetFeature] = useState<any>(false);
    const [highlightId, setHighlightId] = useState('');
    const [highlightType, setHighlightType] = useState('');
    const [geojson, setGeojson] = useState<any>(false);
    const [refreshing, setRefreshing] = useState<any>(false);
    const [latLng, setLatLng] = useState<any>();
    const [zoom, setZoom] = useState<any>();
    const [bounds, setBounds] = useState<any>();
    const [boundsNE, setBoundsNE] = useState<any>(); // coordonnées en EPSG2154
    const [boundsSW, setBoundsSW] = useState<any>(); // coordonnées en EPSG2154
    const [locateClicked, setLocateClicked] = useState<number>(0);
    const [highlightLineWeight, setHighlightLineWeight] = useState<number>(8);
    const [activeLayers, setActiveLayers] = useState<any>([]);
    const [activePatrimoineLayers, setActivePatrimoineLayers] = useState<any>([]);
    const [conformiteLayers, setConformiteLayers] = useState<any>(false);
    const [comLayersGroup, setComLayersGroup] = useState<any>(L.layerGroup([]));
    const [intercomLayersGroup, setIntercomLayersGroup] = useState<any>(L.layerGroup([]));
    const [reseauEuGroup, setReseauEuGroup] = useState<any>(L.layerGroup([]));
    const [reseauEpGroup, setReseauEpGroup] = useState<any>(L.layerGroup([]));
    const [reseauUnGroup, setReseauUnGroup] = useState<any>(L.layerGroup([]));
    const [reseauInconnuGroup, setReseauInconnuGroup] = useState<any>(L.layerGroup([]));
    const osm_uri = LeafletMapConfig.OSM_URI as string;
    const geoserverUrlBase = LeafletMapConfig.GEOSERVER_HOST + "/geoserver/uuid-" + contractId;
    // const geoserverWmsUrlBase = LeafletMapConfig.GEOSERVER_WMS_HOST + "/geoserver/uuid-" + contractId;
    const geoserverWfsUrlBase = LeafletMapConfig.GEOSERVER_WFS_HOST + "/geoserver/uuid-" + contractId;
    const geoserver1UrlBase = LeafletMapConfig.GEOSERVER1_HOST + "/geoserver/uuid-" + contractId;
    const geoserver2UrlBase = LeafletMapConfig.GEOSERVER2_HOST + "/geoserver/uuid-" + contractId;
    const geoserver3UrlBase = LeafletMapConfig.GEOSERVER3_HOST + "/geoserver/uuid-" + contractId;
    const apiUrlBase = LeafletMapConfig.API_HOST + "/api/" + contractId;
    const geoserverFeatureUrlBase = geoserver1UrlBase + "/ows?service=WFS&version=1.0.0&request=GetFeature&outputFormat=application%2Fjson&srsName=EPSG:4326"; // get highlight line
    const geoserverLegendUrl = geoserver1UrlBase + "/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&height=12&weight=12&srs=EPSG:4326&styles&format=image/png&layer=";

    const [pltIdentList, setPltIdentList] = useState<any>([]);

    let highlightLineStyle = {
        color: "#66ffff",
        weight: highlightLineWeight,
        opacity: 1,
        radius: 8,
        fillOpacity: 0,
        smoothFactor: 1
    };

    const [layerGeojson, setLayerGeojson] = useState<any>({
        conformity: {},
        patrimoine: {},
    });

    const CircleBlueHtml = ReactDOMServer.renderToString(<CircleBlue/>);

    const CrossSvgHtml = ReactDOMServer.renderToString(<CrossSvg/>);

    const circleIcon = L.divIcon({
        html: CircleBlueHtml,
        className: "highlightPoint",
        iconSize: [30, 30],
        iconAnchor: [12, 10]
    });

    const crossIcon = L.divIcon({
        html: CrossSvgHtml,
        className: "highlightPoint",
        iconSize: [12, 12],
        iconAnchor: [24, 24]
    });

    const osmLayer = L.tileLayer(osm_uri, {
        ...zooms,
        attribution: 'Map data &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, CC-BY-SA',
    });

    let baseLayerChildren = [{
        selected: true,
        label: 'OpenStreetMap',
        layer: osmLayer,
        key: "osmLayer",
    }];

    baseLayers.map((baseLayer: { layer: any; uri: string; label: any; key: string;}) => {
        let layerOptions = {
            maxZoom: LeafletMapConfig.ZOOM_MAX,
            maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
            version: '1.3.0',
            layers: baseLayer.layer
        };
        let baseLayerObject = L.tileLayer.wms('https://data.geopf.fr/wms-r/wms?', layerOptions);
        baseLayerChildren.push({
            selected: true,
            label: baseLayer.label,
            key: baseLayer.key,
            layer: baseLayerObject
        });
    });

    const baseTree = {
        label: 'Galerie de fonds de plan',
        children: baseLayerChildren
    };

    const firstLogin = mapInfo.firstLogin;
    const treeOptions = {
      // collapsed: false, // Keep open
      closedSymbol: '➤',
      openedSymbol: '▼',
    };

    function coordsToLatLng (coords: [number, number] | undefined) {
        if (undefined !== coords) {
            const latlng: LatLngLiteral = {
                lat: coords[1],
                lng: coords[0]
            }
            return latlng;
        }
        return undefined;
    }

    function doShowHighlight (highlight: any) {
        if (highlight.show) {
            setShowHighlight(highlight.show);
            if (highlight.point !== undefined) {
                setHighlightCircle("conformity" === highlight.type);
                setGeojson(false);
                setHighlightPoint(highlight.point);
            } else {
                setHighlightPoint(false);
                if (highlight.line !== undefined) {
                    setGeojson(highlight.line);
                    setHighlightId(highlight.id);
                    setHighlightType(highlight.type);
                }
                else if (highlightId !== highlight.id || highlightType !== highlight.type) {
                    let geojsonurl = geoserverFeatureUrlBase + "&typeName=uuid-" + contractId + ":" + highlight.type + "&CQL_FILTER=ASSET_ID=%27" + highlight.id + "%27";
                    if ("A_VISITE_polygon" === highlight.type) {
                        geojsonurl = geoserverFeatureUrlBase + "&typeName=uuid-" + contractId + ":" + highlight.type + "&CQL_FILTER=asset_id=%27" + highlight.id + "%27";
                    }
                    Request.sendRequest(geojsonurl, HTTPMethods.GET, {}, false, true)
                        .then((res: { data: any }) => {
                            let geojsonResponse = res.data;
                            delete geojsonResponse.crs;
                            setGeojson(geojsonResponse);
                            setHighlightId(highlight.id);
                            setHighlightType(highlight.type);

                            return geojsonResponse;
                        });
                }
            }
        }
    }

    function refreshActiveLayers (save = false) {
        // console.log(refreshing)
        if (refreshing) {
            return;
        }
        // return;
        setRefreshing(true);
        let layers: string[] = [];
        let pLayers: string[] = []; // Temporary collection for active Patrimoine layers

        conformityLayers.map((conformityLayer: string) => {
            if (undefined !== layerGeojson['conformity'][conformityLayer]) {
                map.removeLayer(layerGeojson['conformity'][conformityLayer]);
            }
        });
        setConformiteLayers(false);

        const subGroupSubFixes = [
            ':EU:COM',
            ':EU:INTERCOM',
            ':EP:COM',
            ':EP:INTERCOM',
            ':UN:COM',
            ':UN:INTERCOM',
            ':INCONNU:COM',
            ':INCONNU:INTERCOM',
        ]

        wfsPatrimoineLayers.map((wfsPatrimoineLayer: string) => {
            if (undefined !== layerGeojson['patrimoine'][wfsPatrimoineLayer]) {
                map.removeLayer(layerGeojson['patrimoine'][wfsPatrimoineLayer]);
            }
            subGroupSubFixes.map((subGroupSubFix: string) => {
                const wfsPatrimoineLayerSubGroupSubFix = wfsPatrimoineLayer + subGroupSubFix;
                if (undefined !== layerGeojson['patrimoine'][wfsPatrimoineLayerSubGroupSubFix]) {
                    map.removeLayer(layerGeojson['patrimoine'][wfsPatrimoineLayerSubGroupSubFix]);
                }
            });
        });

        mixPatrimoineLayers.map((mixPatrimoineLayer: string) => {
            if (undefined !== layerGeojson['patrimoine'][mixPatrimoineLayer]) {
                map.removeLayer(layerGeojson['patrimoine'][mixPatrimoineLayer]);
            }
            subGroupSubFixes.map((subGroupSubFix: string) => {
                const mixPatrimoineLayerSubGroupSubFix = mixPatrimoineLayer + subGroupSubFix;
                // console.log(mixPatrimoineLayerSubGroupSubFix)
                if (undefined !== layerGeojson['patrimoine'][mixPatrimoineLayerSubGroupSubFix]) {
                    map.removeLayer(layerGeojson['patrimoine'][mixPatrimoineLayerSubGroupSubFix]);
                }
            });
        });

        // if ('conformity' === mapInfo.highlight.type) {
        //     conformityLayers.map((conformityLayer: string) => {
        //         if (undefined !== layerGeojson['conformity'][conformityLayer]) {
        //             layerGeojson['conformity'][conformityLayer].addTo(map);
        //         }
        //     });
        //     setConformiteLayers(true);
        // }

        // if ('conformity' === mapInfo.highlight.type) {
        //     conformityLayers.map((conformityLayer: string) => {
        //         if (undefined !== layerGeojson['conformity'][conformityLayer]) {
        //             map.removeLayer(layerGeojson['conformity'][conformityLayer]);
        //             layerGeojson['conformity'][conformityLayer].addTo(map);
        //         }
        //     });
        //     setConformiteLayers(true);
        // } else {
        //     conformityLayers.map((conformityLayer: string) => {
        //         if (undefined !== layerGeojson['conformity'][conformityLayer]) {
        //             map.removeLayer(layerGeojson['conformity'][conformityLayer]);
        //         }
        //     });
        //     setConformiteLayers(false);
        // }
        Object.keys(map._layers).map((key) => {
            const activeLayer = map._layers[key];
            if (undefined === activeLayer) {
                return false;
            }
            const layerName = activeLayer.options.layers;
            if (undefined === layerName) {
                return false;
            }
            const _url = activeLayer._url;
            let url:string = '';
            let activeLayerFilterTypo:string = '';
            let activeLayerFilterReseau:string = '';
            let activeLayerFilterMo:string = '';
            if (_url !== undefined) {
                url = _url.toString();
            } else {
                return false;
            }

            let indexName: any;
            let indexNameWithFilter: any;
            indexName = layerName.split(":")[1];
            indexNameWithFilter = indexName;

            // console.log(indexName)
            // console.log(indexNameWithFilter)
            if (url !== undefined) {
               const indexFilterTypo = url.indexOf('TYPO=%27'); // VANSEC VANGUI
               if (-1 !== indexFilterTypo) {
                   activeLayerFilterTypo = url.substring(indexFilterTypo+8, indexFilterTypo+14);
                   // console.log(activeLayerFilterTypo)
                   indexNameWithFilter += ':'+activeLayerFilterTypo;
               }
               const indexFilterReseau = url.indexOf('RESEAU=%27'); // EU EP UN INCONNU
               if (-1 !== indexFilterReseau) {
                   activeLayerFilterReseau = url.substring(indexFilterReseau+10, indexFilterReseau+17);
                   const activeLayerFilterReseauSubstring = activeLayerFilterReseau.substring(2, 7);
                   if ('%27%2' === activeLayerFilterReseauSubstring) {
                       activeLayerFilterReseau = activeLayerFilterReseau.substring(0, 2);
                   }
                   // console.log(activeLayerFilterReseau)
                   indexNameWithFilter += ':'+activeLayerFilterReseau;
               }
               const indexFilterMo = url.indexOf('MO=%27'); // COM INTERCOM
               if (-1 !== indexFilterMo) {
                   activeLayerFilterMo = url.substring(indexFilterMo+6, indexFilterMo+14);
                   const activeLayerFilterMoSubstring = activeLayerFilterMo.substring(0, 3);
                   if ('COM' === activeLayerFilterMoSubstring) {
                       activeLayerFilterMo = 'COM';
                   }
                   indexNameWithFilter += ':'+activeLayerFilterMo;
                   // console.log(activeLayerFilterMo)
               }
            }

            if (
                conformityLayers.indexOf(indexName) !== -1
                && undefined !== layerGeojson['conformity'][indexName]
            ) {
                layerGeojson['conformity'][indexName].addTo(map);
                setConformiteLayers(true);
            }

            if (
                wfsPatrimoineLayers.indexOf(indexName) !== -1
                && undefined !== layerGeojson['patrimoine'][indexNameWithFilter]
            ) {
                layerGeojson['patrimoine'][indexNameWithFilter].addTo(map);
            }

            if (
                mixPatrimoineLayers.indexOf(indexName) !== -1
                && undefined !== layerGeojson['patrimoine'][indexNameWithFilter]
                && map.getZoom() > 16
            ) {

                layerGeojson['patrimoine'][indexNameWithFilter].addTo(map);
            }

            if (!layers.includes(layerName)) {
               Object.keys(assetLayers).map((key: any) => {
                   const layer = assetLayers[key];
                   const hasSubGroup = layer['metadata']['hasSubGroup'] !== undefined ?? false;
                   const filterTypo = layer['metadata']['filterTypo'] ?? false;
                   const filterMo = layer['metadata']['filterMo'] ?? false;
                   const filterReseau = layer['metadata']['filterReseau'] ?? false;
                   if (layer['layer'] === indexName && !hasSubGroup) {
                       let layerNameWithFilter = layerName;
                       if (filterTypo) {
                           if (filterTypo === activeLayerFilterTypo) {
                               layerNameWithFilter = layerName + ':' + filterTypo;
                           }
                           if (filterReseau === activeLayerFilterReseau) {
                               layerNameWithFilter = layerName + ':' + filterReseau;
                           }
                           if (filterMo === activeLayerFilterMo) {
                               layerNameWithFilter = layerName + ':' + filterMo;
                           }
                           // console.log(layerNameWithFilter)
                           if (-1 === layers.indexOf(layerNameWithFilter)) {
                               layers.push(layerNameWithFilter);
                           }
                       } else if (-1 === layers.indexOf(layerName)) {
                           layers.push(layerName);
                       }
                       if (-1 !== patrimoineLayers.indexOf(indexName) && -1 === pLayers.indexOf(indexName)) {
                           pLayers.push(indexName);
                       }
                   }
                   return true;
               });
            }
            return true;
        });
        // console.log(' 446 layers')
        // console.log(layers)
        // console.log(pLayers)
        // console.log(pLayers)
        setActiveLayers(layers);
        setActivePatrimoineLayers(pLayers);

        if (save && 0 !== layers.length && !refreshing) {
            // console.log('setmapinfo')
            setMapInfo({
                assetLayers: layers
            });
            setTimeout(() => {
                updateVisibleLayers(layers);
                setRefreshing(false);
            }, 1000);
        }
        setTimeout(() => {
            updateVisibleLayers(layers);
            setRefreshing(false);
        }, 1000);
    }

    function getColor(feature: any, indexName: any){
        const properties = feature.properties;
        if (indexName === "A_BRANCH_line") {
            switch(properties.STATUT_PAT) {
                case 'EN SERVICE':
                    switch(properties.RESEAU) {
                        case 'EU':
                            return '#ff0000';
                        case 'EP':
                            return '#00ff00';
                        case 'UN':
                            return '#ce23b1';
                    }
                    return '#000000';
                case 'EN PROJET':
                case 'PROJET':
                    return '#a6cee3';
                case 'DEPOSE':
                    return '#ff7f00';
                default:
                    return '#0000ff';
            }
        }
        if (indexName === "A_COLLEC_line") {
            if (properties.ECOUNORM === "REFOUL") {
                return '#0000ff';
            }
            switch(properties.STATUT_PAT) {
                case 'EN SERVICE':
                    switch(properties.RESEAU) {
                        case 'EU':
                            return '#ff0000';
                        case 'EP':
                            return '#00ff00';
                        case 'UN':
                            return '#ce23b1';
                    }
                    return '#0000ff';
                case 'EN PROJET':
                    return '#a6cee3';
                case 'DEPOSE':
                    return '#ff7f00';
                default:
                    return '#0000ff';
            }
        }
        return '#000000';
    }

    function getWeight(feature: any, indexName: any){
        if (indexName === "A_BRANCH_line") {
            return 3;
        }
        if (indexName === "A_COLLEC_line") {
            if (feature.properties.MO === "INTERCOM") {
                return 3;
            }
            return 2;
        }
        return 3;
    }

    function getOpacity(feature: any, indexName: any){
        if (feature.properties.STATUT_PAT === 'HORS SERVICE') {
            return 0;
        }
        return 1;
    }

    function getDashArray(feature: any, indexName: any){
        if (indexName === "A_BRANCH_line") {
            return '3,3';
        }
        if (indexName === "A_COLLEC_line") {
            if (feature.properties.ECOUNORM === "REFOUL") {
                return '10 4';
            }
            return '';
        }
        return '3,3';
    }

    function renderWfsPatrimoineLayer(layerUrl: string, layer: AssetLayer, boundsSW: any, boundsNE: any, zoom: any) {
        // après démarrage et après chaque zoom/déplacement sur la carte, charger les assets wfs selon position
        const key = layer['layer'];
        let layerName = key;
        let layerNameWithFilter = key;
        const filterTypo = layer['metadata']['filterTypo'] ?? false;
        const filterMo = layer['metadata']['filterMo'] ?? false;
        const filterReseau = layer['metadata']['filterReseau'] ?? false;
        const hasSubGroup = layer['metadata']['hasSubGroup'] === 'true' ?? false;
        if (hasSubGroup) {
            return;
        }
        let layerKey = `uuid-${contractId}:${key}`;

        const isWfsPatrimoineLayer = -1 !== wfsPatrimoineLayers.indexOf(key);
        const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);

        if (isMixPatrimoineLayer && boundsNE === null && boundsSW === null) {
            return;
        }
        if (isMixPatrimoineLayer && zoom < 16) {
            return;
        }
        layerUrl = layerUrl + '&version=1.0.0';
        layerUrl = layerUrl + '&request=GetFeature';
        layerUrl = layerUrl+ '&maxFeatures=2000';
        layerUrl = layerUrl+ '&typeName=' + layerKey;
        layerUrl = layerUrl+ '&outputFormat=application%2Fjson';
        layerUrl = layerUrl+ '&srsName=EPSG:4326';
        let filtered = false;
        if (boundsNE && boundsSW) {
            layerUrl = layerUrl+ '&CQL_FILTER=BBOX(geom,'+boundsNE.x+','+boundsNE.y+','+boundsSW.x+','+boundsSW.y+')';
            filtered = true;
        }

        if (filterTypo) {
            layerUrl += '&CQL_FILTER=TYPO=%27' + filterTypo +'%27';
            layerNameWithFilter += ':'+filterTypo;
            filtered = true;
        }
        if (filterReseau) {
            layerNameWithFilter += ':'+filterReseau;
            if (filtered) {
                layerUrl += '%20AND%20RESEAU=%27' + filterReseau +'%27';
            } else {
                layerUrl += '&CQL_FILTER=RESEAU=%27' + filterReseau +'%27';
            }
            filtered = true;
        }
        if (filterMo) {
            layerNameWithFilter += ':'+filterMo;
            if (filtered) {
                layerUrl += '%20AND%20MO=%27' + filterMo +'%27';
            } else {
                layerUrl += '&CQL_FILTER=MO=%27' + filterMo +'%27';
            }
        }

        try {
            Request.get(layerUrl).then(async (responseData: any) => {
                const wfsPatrimoineGeojson = L.geoJSON(responseData.data, {
                    style: function (feature: any) {
                        let indexNameFragments = feature.id.split(".");
                        const indexName = indexNameFragments[0];
                        let className = '';
                        if (indexName === "A_COLLEC_line") {
                            if (feature.properties.HAUTEUR !== undefined) {
                                className = feature.properties.HAUTEUR;
                            }
                        }
                        return {
                            color: getColor(feature, indexName),
                            weight: getWeight(feature, indexName),
                            opacity: getOpacity(feature, indexName),
                            dashArray: getDashArray(feature, indexName),
                            lineJoin: 'round'
                        };
                    },
                    pointToLayer: function (feature: any, latlng) {
                        let opacity = 1;
                        let reseau: string = feature.properties.RESEAU;
                        if (reseau === "INCONNU") {
                            reseau = "A";
                            opacity = 0;
                        }
                        if (reseau === "ET") reseau = "A"; // VENTOU
                        let options = {};
                        if (!reseau) reseau = "A";

                        if (key === "A_REGARD_point") {
                            let ident: string = feature.properties?.IDENT;
                            if (Array.isArray(pltIdentList) && pltIdentList.indexOf(ident) !== -1) {
                                if (reseau === "EU" || reseau === "EP") {
                                    reseau = reseau + "A";
                                }
                            }
                        }

                        if (undefined !== wfsIcons[key] && undefined !== wfsIcons[key][reseau]) {
                            options = {
                                icon: wfsIcons[key][reseau],
                                opacity: opacity
                            };
                        }
                        return L.marker(latlng, options);
                    },
                    onEachFeature: function (feature, layer) {
                        layer.unbindTooltip();
                        if (feature !== undefined && feature.id !== undefined && typeof feature.id === "string") {
                            let indexNameFragments = feature.id.split(".");
                            const indexName = indexNameFragments[0];
                            if (
                                indexName === "A_REGARD_point"
                                && zoom > 17
                                && feature.properties.STATUT_PAT !== 'HORS SERVICE'
                                && '420d59de-c3ed-11ed-a0a0-0242ad15150b' === contractId
                            ) {
                                let ident: string = feature.properties?.IDENT;
                                let identTail = ident.substring(19);
                                setTimeout(() => {
                                    layer.bindTooltip(identTail, {
                                        direction: 'right',
                                        permanent: true,
                                        offset: [10, 0],
                                        className: 'regard-tooltip'
                                    });
                                }, 2000);
                            }
                        }

                        layer.on({
                            click: function (e) {
                                hideReport();
                                const feature = e.target.feature;
                                const assetId = feature.properties.ASSET_ID;
                                let indexNameFragments = feature.id.split(".");
                                let indexName = indexNameFragments[0];
                                const geometry = feature.geometry;
                                const type = geometry.type;
                                const coordinates = geometry.coordinates;
                                let point: LatLngLiteral | undefined = undefined;
                                let latlng = undefined;
                                let highlight: HighlightInterface = {
                                    show: true,
                                    type: indexName,
                                    id: assetId,
                                    point: point,
                                    line: undefined,
                                };
                                if (type === 'Point') {
                                    if ('T_PLT_point' !== indexName) {
                                        latlng = coordsToLatLng(coordinates);
                                        highlight.point = latlng;
                                        // console.log('setmapinfo')
                                        setMapInfo({
                                            center: latlng,
                                            extent: null,
                                            highlight: highlight,
                                            autoZoom: true,
                                        });
                                    }
                                } else {
                                    if ("A_COLLEC_line" === indexName && feature.properties.MO === "INTERCOM") {
                                        setHighlightLineWeight(16);
                                    } else {
                                        setHighlightLineWeight(8);
                                    }
                                    highlight.line = feature;
                                    // console.log('setmapinfo')
                                    setMapInfo({
                                        extent: feature.bbox,
                                        highlight: highlight,
                                        autoZoom: true
                                    });
                                }
                                const layerAssetId = indexName.toLowerCase() + '_' + assetId;
                                if (contract.label === "SIAHVY" && indexName === "A_TRAPHY_point") {
                                    indexName = 'A_TRAPHY_point_siahvy';
                                }
                                if ('T_PLT_point' !== indexName) {
                                    const itemName = t(indexName.toLowerCase(), {ns: 'asset'}) + " " + assetId;
                                    const name = t("category.asset");
                                    let search = props.search;
                                    if (search !== undefined) {
                                        search.detail.shownDetailCategory = categories.ASSET;
                                        search.detail.shownDetailId = assetId;
                                    }
                                    showDetail(layerAssetId, search, itemName, name, "synthesis", true);
                                    doShowHighlight(highlight);
                                    // setGetFeature(false);
                                }
                            },
                            mouseover: function (e) {
                                if (isWfsPatrimoineLayer) {
                                    const feature = e.target.feature;
                                    let indexNameFragments = feature.id.split(".");
                                    let indexName = indexNameFragments[0];
                                    if ('420d59de-c3ed-11ed-a0a0-0242ad15150b' === contractId && 'A_TRAPHY_point' === indexName) {
                                        indexName = 'a_traphy_point_siahvy';
                                    }
                                    const indexLabel = t(indexName.toLowerCase(), {ns: 'asset'});
                                    const properties = feature.properties;
                                    const coordinates = feature.geometry.coordinates;
                                    let popupContent = '<p class="asset-popup">' + indexLabel + '</p>';
                                    if (properties.NOM !== '' && properties.NOM !== null) {
                                        popupContent += '<p class="asset-popup">' + properties.NOM + '</p>';
                                    }
                                    if (properties.ADRESSE !== '' && properties.ADRESSE !== null) {
                                        popupContent += '<p>' + properties.ADRESSE + '</p>';
                                    }
                                    popupContent += '<p>' + properties.CODINSEE + ' ' + properties.COMMUNE + '</p>';
                                    layer.bindPopup(popupContent);
                                    if (coordinates.length === 2) {
                                        layer.openPopup([coordinates[1], coordinates[0]]);
                                    } else {
                                        // site [Array(100)]
                                        layer.openPopup([coordinates[0][0][1], coordinates[0][0][0]]);
                                    }
                                }
                            },
                            mouseout: function (e) {
                                if (isWfsPatrimoineLayer) {
                                    layer.closePopup();
                                }
                            },
                        })
                    }
                });

                // commenting this because dispear after a slash
                if (undefined !== layerGeojson['patrimoine'][key]) {

                    // console.log('remove ' + key)
                    map.removeLayer(layerGeojson['patrimoine'][key]);
                }

                let layerGroup = new L.LayerGroup();
                layerGroup.addLayer(wfsPatrimoineGeojson);
                layerGeojson['patrimoine'][layerNameWithFilter] = layerGroup;

                setRefreshing(true);
                // console.log('before refresh')
                // refreshActiveLayers();
            }).catch((e: any) => {
                // console.log(e)
                // console.log('get wfs error')
            }).finally(() => {
                // console.log('get wfs error')
            });
        } catch (e) {
            console.log('get wfs error');
        }
        // setRefreshing(true);
        // console.log('before refresh')
        // refreshActiveLayers();
    }

    function getLayerUrl(layer: AssetLayer) {
        const key = layer['layer'];
        const group = layer['group'];
        const filterTypo = layer['metadata']['filterTypo'] ?? false;
        const filterMo = layer['metadata']['filterMo'] ?? false;
        const filterReseau = layer['metadata']['filterReseau'] ?? false;
        const filterConformite = layer['metadata']['filterConformite'] ?? false;

        let layerUrl: string = '';
        let layerUrlWfs: string = '';

        if (-1 !== geoserver1Layers.indexOf(key)) {
            layerUrl = geoserverUrlBase;
            layerUrlWfs = geoserverUrlBase;
        }

        if (-1 !== geoserver2Layers.indexOf(key)) {
            layerUrl = geoserver1UrlBase;
            layerUrlWfs = geoserver1UrlBase;
        }

        if (-1 !== geoserver3Layers.indexOf(key)) {
            layerUrl = geoserver2UrlBase;
            layerUrlWfs = geoserver2UrlBase;
        }

        if (-1 !== geoserver4Layers.indexOf(key)) {
            layerUrl = geoserver3UrlBase;
            layerUrlWfs = geoserver3UrlBase;
        }

        layerUrl = layerUrl + '/wms?service=WMS&tiled=true';
        layerUrlWfs = layerUrlWfs + '/ows?service=WFS';

        const isGroupControl = group == 'Conformit&eacute; des enqu&ecirc;tes';
        const isWfsPatrimoineLayer = -1 !== wfsPatrimoineLayers.indexOf(key);
        const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);
        if (isGroupControl) {
            layerUrl = apiUrlBase + '/control/pdc?format=geojson&conformite=' + filterConformite;
        } else if (isWfsPatrimoineLayer) {
            layerUrl = layerUrlWfs;
        } else if (isMixPatrimoineLayer) {
            layerUrlWfs = geoserverWfsUrlBase + '/ows?service=WFS';
        }

        // let layerName = layerKey;
        let filtered = false;
        if (filterTypo) {
            layerUrl += '&CQL_FILTER=TYPO=%27' + filterTypo +'%27';
            layerUrlWfs += '&CQL_FILTER=TYPO=%27' + filterTypo +'%27';
            filtered = true;
        }
        if (filterReseau) {
            if (filtered) {
                layerUrl += '%20AND%20RESEAU=%27' + filterReseau +'%27';
            } else {
                layerUrl += '&CQL_FILTER=RESEAU=%27' + filterReseau +'%27';
            }
            filtered = true;
        }
        if (filterMo) {
            if (filtered) {
                layerUrl += '%20AND%20MO=%27' + filterMo +'%27';
            } else {
                layerUrl += '&CQL_FILTER=MO=%27' + filterMo +'%27';
            }
        }

        return [layerUrl, layerUrlWfs];
    }

    useEffect(() => {
        // console.log('useEffect zoom and show highlight')
        // zoom and show highlight
        if (map) {
            // console.log('map info change')
            const { highlight, autoZoom, firstLogin, zoom } = mapInfo;
            const { doShowDetail } = highlight;
            setDoZoom(autoZoom);
            if (autoZoom || doZoom) {
                setDoZoom(false);
                // console.log('in autozoom || do zoom')
                const lng = map.getCenter().lng;
                const lat = map.getCenter().lat;
                const { center, extent } = mapInfo;
                if (extent !== null) {
                    map.fitBounds([
                        [extent[1], extent[0]],
                        [extent[3], extent[2]],
                    ]);
                    setTimeout(() => {
                        map.invalidateSize();
                    }, 10);
                    setDoZoom(false);
                } else if (center !== null && center['longitude'] !== lng && center['latitude'] !== lat) {
                    if (undefined !== center.lat) {
                        // console.log(center)
                        map.flyTo([center.lat, center.lng], zoom);
                    } else {
                        map.flyTo([center.longitude, center.latitude], zoom);
                    }
                    setTimeout(() => {
                        map.invalidateSize();
                    }, 10);
                    setDoZoom(false);
                }

                // console.log('fly3d to position')
                // console.log('->->->->->-')
                // fly3dToPosition(mapInfo);
            }
            doShowHighlight(highlight);
            setShowHighlight(highlight.show);
            if ("conformity" === highlight.type && search?.flag.showResultDetail) {
                showDetailControl(highlight.id, props.search, highlight.id, t("pdc"), showReport);
            } else if (autoZoom && doShowDetail && doStateShowDetail) {
                setDoStateShowDetail(false);
                const layerAssetId = highlight.type.toLowerCase() + '_' + highlight.id;
                let indexName = highlight.type;
                if (contract.label === "SIAHVY" && indexName === "A_TRAPHY_point") {
                    indexName = 'A_TRAPHY_point_siahvy';
                }
                const itemName = t(indexName.toLowerCase(), {ns: 'asset'}) + " " + highlight.id;
                const name = t("category.asset");
                showDetail(layerAssetId, props.search, itemName, name, "synthesis", true);
                setTimeout(() => {
                    setDoStateShowDetail(true);
                }, 2000);
            }

            if (firstLogin) {
                getRegardPlt().then((data)=> {
                    setPltIdentList(data);
                });
                setMapInfo({
                    firstLogin: false,
                });
            }

            // console.log('fly3d to position')
            // console.log(mapInfo.center)
            // console.log(mapInfo.zoom)
            // fly3dToPosition(mapInfo, true);
            // fly3dToPosition(mapInfo, false);
        }
    }, [ mapInfo ]);

    useEffect(() => {
        // get assets in bbox vu
        if (undefined !== boundsSW && undefined !== boundsNE && !refreshing) {
            setTimeout(() => {
                // console.log('useEffect moving/zooming')
                setRefreshing(true);
                reloadWfsLayers(zoom);
            }, 1000);
        }
    }, [ boundsSW, boundsNE, zoom ]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (map) {
                refreshActiveLayers()
                // console.log('interval refresh')
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [map]);

    function reloadWfsLayers(zoom: number) {
        let reorderedAssetLayers : any = [];
        assetLayers.map((layer: AssetLayer, i: number) => {
            const key = layer['layer'];
            const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);
            if (isMixPatrimoineLayer) {
                if ('T_PLT_point' === key) {
                    reorderedAssetLayers = [layer, ...reorderedAssetLayers];
                } else {
                    reorderedAssetLayers.push(layer);
                }
            }
        });

        reorderedAssetLayers.map((layer: AssetLayer) => {
            const key = layer['layer'];
            const wfsMinZoom = layer['metadata']['wfsMinZoom'] ?? 16;
            let [layerUrl, layerUrlWfs] = getLayerUrl(layer);

            const isActive = -1 !== activePatrimoineLayers.indexOf(key);
            if (isActive && zoom >= wfsMinZoom) {
                renderWfsPatrimoineLayer(layerUrlWfs, layer, boundsNE, boundsSW, zoom);
            }
        });
    }

    function preprareOverlayTree(layerObjects: any) {
        let layerChildren: LayerObject[] = [];
        let groupChildren: GroupObject[] = [];
        let overlaysTree = {
            label: 'Objets SIG',
            children: groupChildren,
        };

        Object.keys(layerObjects).map((key) => {
            // Patrimoine, Limit adminstratives, Curage, Autres
            let group = {
                label: key,
                selectAllCheckbox: true,
                children: layerChildren,
            };
            let subGroupObject: any = false; // Regarde, Branchement, Tronçons / Equipement de Régulation
            let sub2GroupObject: any = false; // EU, EP,                       / VANSEC, VANGUI
            let sub3GroupObject: any = false; //                               / EU, EP
            layerObjects[key].map((layerObject: { layer: any, label: string, subGroup?: boolean, hasSubGroup?: boolean }) => {
                if (layerObject.hasSubGroup) {
                    if (subGroupObject) {
                        if (sub2GroupObject) {
                            if (sub3GroupObject) {
                                if (sub3GroupObject.label !== layerObject.subGroup) {
                                    sub2GroupObject['children'].push(sub3GroupObject);
                                    sub3GroupObject = false;
                                    if (sub2GroupObject.label === layerObject.subGroup) {
                                        sub3GroupObject = {
                                            label: layerObject['label'],
                                            selectAllCheckbox: true,
                                            collapsed: true,
                                            children: [],
                                        };
                                    }
                                }
                            } else {
                                if (sub2GroupObject.label === layerObject.subGroup) {
                                    sub3GroupObject = {
                                        label: layerObject['label'],
                                        selectAllCheckbox: true,
                                        collapsed: true,
                                        children: [],
                                    };
                                }
                            }
                            if (sub2GroupObject.label !== layerObject.subGroup) {
                                subGroupObject['children'].push(sub2GroupObject);
                                sub2GroupObject = false;
                                if (layerObject.subGroup === false) {
                                    group['children'].push(subGroupObject);
                                    subGroupObject = {
                                        label: layerObject['label'],
                                        selectAllCheckbox: true,
                                        collapsed: true,
                                        children: [],
                                    };
                                } else {
                                    sub2GroupObject = {
                                        label: layerObject['label'],
                                        selectAllCheckbox: true,
                                        collapsed: true,
                                        children: [],
                                    };
                                }
                            }
                        } else {
                            sub2GroupObject = {
                                label: layerObject['label'],
                                selectAllCheckbox: true,
                                collapsed: true,
                                children: [],
                            };
                        }
                    } else {
                        subGroupObject = {
                            label: layerObject['label'],
                            selectAllCheckbox: true,
                            collapsed: true,
                            children: [],
                        };
                    }
                } else {
                    if (layerObject.subGroup) {
                        if (subGroupObject.label === layerObject.subGroup) {
                            if (sub2GroupObject) {
                                subGroupObject['children'].push(sub2GroupObject);
                                sub2GroupObject = false;
                            }
                            subGroupObject['children'].push(layerObject);
                        }
                        if (sub2GroupObject.label === layerObject.subGroup) {
                            sub2GroupObject['children'].push(layerObject);
                        }
                        if (sub3GroupObject.label === layerObject.subGroup) {
                            sub3GroupObject['children'].push(layerObject);
                        }
                    } else {
                        if (sub2GroupObject) {
                            subGroupObject['children'].push(sub2GroupObject);
                            sub2GroupObject = false;
                        }
                        if (subGroupObject) {
                            group['children'].push(subGroupObject);
                            subGroupObject = false;
                        }
                        delete layerObject.subGroup;
                        delete layerObject.hasSubGroup;
                        group['children'].push(layerObject);
                    }
                }
                return true;
            });
            overlaysTree['children'].push(group);
            layerChildren = [];

            return true;
        });

        return overlaysTree;
    }

    function renderGroupControl(layer: AssetLayer, layerUrl: string) {
        const key = layer['layer'];
        const conformityIconHtml: any = {
            conformite_conforme: ReactDOMServer.renderToString(<CircleBlackOutlineGreen/>),
            conformite_nonConforme: ReactDOMServer.renderToString(<CircleBlackOutlineRed/>),
            conformite_unknown: ReactDOMServer.renderToString(<CircleBlack/>),
        }
        const conformityIcon = L.divIcon({
            html: conformityIconHtml[key],
            className: "conformityPoint_" + layer.layer
        });

        Request.get(layerUrl).then(async (responseData: any) => {
            const conformityGeojson = L.geoJSON(responseData.data, {
                pointToLayer: function(feature,latlng){
                    return L.marker(latlng,{icon: conformityIcon});
                },
                onEachFeature: function (feature, layer) {
                    layer.on({
                        click: function (e){
                            hideReport();
                            const pdcId = e.target.feature.properties.idPDC;
                            const coordinates = e.target.feature.geometry.coordinates;
                            const latlng = coordsToLatLng(coordinates);
                            let highlight: { show: boolean; id: any; type: string; point?: LatLngLiteral } = {
                                show: true,
                                type: 'conformity',
                                id: pdcId,
                                point: latlng,
                            };
                            highlight.point = latlng;
                            setMapInfo({
                                center: latlng,
                                extent: null,
                                highlight: highlight,
                                autoZoom: true,
                                zoom: 17, // click on map picto and zoom to 50m
                            });
                            const layerAssetId = pdcId;
                            const itemName = pdcId;
                            const name = t("pdc");
                            let search = props.search;
                            if (search !== undefined) {
                                search.detail.shownDetailCategory = categories.ASSET;
                                search.detail.shownDetailId = pdcId;
                            }
                            showDetailControl(layerAssetId, search, itemName, name);
                            doShowHighlight(highlight);
                            // setGetFeature(false);
                        },
                        mouseover: function (e){
                            const properties = e.target.feature.properties;
                            const coordinates = e.target.feature.geometry.coordinates;
                            layer.bindPopup(
                                '<p class="eca-popup">'+t("survey")+'</p>'+
                                '<p>'+properties.adresse+'</p>'+
                                '<p>'+properties.codePostal+' '+properties.commune+'</p>'
                            );
                            const latlng: LatLngLiteral = {
                                lat: coordinates[1],
                                lng: coordinates[0]
                            }
                            layer.openPopup(latlng);
                        },
                        mouseout: function (e){
                            layer.closePopup();
                        },
                    })
                }
            });

            let layerGroup = new L.LayerGroup();
            layerGroup.addLayer(conformityGeojson);
            layerGeojson['conformity'][key] = layerGroup;
            // console.log('before refresh')
            // refreshActiveLayers();
        }).catch(e =>{
            // console.log(e);
            console.log('error wfs');
        });
    }

    useEffect(() => {
        if (map) {
            const center = map.getCenter();
            const bounds = map.getBounds();
            const zoom = map.getZoom();
            setZoom(zoom);
            setLatLng(center);
            setBounds(bounds);
            if (bounds) {
                try {
                    const urlEpsgNE = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lat, 'ceil') + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lng, 'ceil');
                    Request.get(urlEpsgNE).then((responseData: any) => {
                        setBoundsNE(responseData.data);
                    });
                    const urlEpsgSW = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lat) + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lng);
                    Request.get(urlEpsgSW).then((responseData: any) => {
                        setBoundsSW(responseData.data);
                    });
                } catch (e) {
                    console.log('srs transform error')

                    return;
                }
            }
            map.zoomControl.setPosition('bottomright');
            if (!pref) {
                let layerObjects: any = {};
                getMapPreferences().then((data: any) => {
                    let center = data.center;
                    if (center !== undefined && center !== {} && center !== null) {
                        const lat = center.lat ?? center.latitude;
                        const lng = center.lng ?? center.longitude;
                        map.flyTo([lat, lng], data.zoom);
                    }
                    setPref(data);
                    let defaultBaseLayer = osmLayer;
                    let selectedBaseLayer = data.mapBackground;
                    if (!firstLogin) {
                        selectedBaseLayer = mapInfo.baseLayer;
                    }
                    baseLayerChildren.map((baseLayerChild) => {
                        if (baseLayerChild.label === selectedBaseLayer) {
                            defaultBaseLayer = baseLayerChild.layer;
                        }
                        return true;
                    });
                    const layerGroup = L.layerGroup([defaultBaseLayer]);
                    layerGroup.addTo(map);

                    assetLayers.map((layer: AssetLayer, i: number) => {
                        const name = layer['metadata']['label'];
                        const key = layer['layer'];
                        const label = layer['label'];
                        const type = layer['type'];
                        const group = layer['group'];
                        const filterTypo = layer['metadata']['filterTypo'] ?? false;
                        const filterMo = layer['metadata']['filterMo'] ?? false;
                        const filterReseau = layer['metadata']['filterReseau'] ?? false;
                        const subGroup = layer['metadata']['subGroup'] ?? false;
                        const hasSubGroup = layer['metadata']['hasSubGroup'] === 'true' ?? false;
                        const defaultSelected = layer['group'] === 'Patrimoine' || layer['layer'] === 'A_SECOUL_point';
                        layerObjects[group] = layerObjects[group] ?? [];
                        const isGroupControl = group == 'Conformit&eacute; des enqu&ecirc;tes';
                        const wfsMinZoom = layer['metadata']['wfsMinZoom'] ?? 16;
                        const layerKey = `uuid-${contractId}:${key}`;
                        const isWfsPatrimoineLayer = -1 !== wfsPatrimoineLayers.indexOf(key);
                        const isMixPatrimoineLayer = -1 !== mixPatrimoineLayers.indexOf(key);
                        let layerName = layerKey;
                        let [layerUrl, layerUrlWfs] = getLayerUrl(layer);
                        let opacity = 0.4;
                        if (group === "Patrimoine"|| layer['layer'] === 'A_SECOUL_point') {
                            opacity = 1;
                        }

                        let userLayerSelected = true;
                        if (data.visibleLayers && -1 === data.visibleLayers.indexOf(layerName)) {
                            userLayerSelected = false;
                        }
                        // When first login, show selected in default
                        let selected = userLayerSelected && (!hasSubGroup) && (!firstLogin || defaultSelected || isGroupControl);

                        let tileLayer, layerOptions;
                        if (isGroupControl) {
                            renderGroupControl(layer, layerUrl);
                            layerOptions = {
                                layers: layerKey,
                                maxZoom: LeafletMapConfig.ZOOM_MAX,
                                maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        } else if (isWfsPatrimoineLayer) {
                            // calculate
                            renderWfsPatrimoineLayer(layerUrlWfs, layer, null, null, zoom);

                            layerOptions = {
                                format: "image/png",
                                layers: layerKey,
                                transparent: true,
                                opacity: opacity,
                                maxZoom: LeafletMapConfig.ZOOM_MAX,
                                maxNativeZoom: LeafletMapConfig.MAX_NATIVE_ZOOM,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        } else if (isMixPatrimoineLayer && zoom >= wfsMinZoom) {
                            // bbox too big 500 do not call without bounds
                            renderWfsPatrimoineLayer(layerUrlWfs, layer, null, null, zoom);

                            layerOptions = {
                                format: "image/png",
                                layers: layerKey,
                                transparent: true,
                                opacity: opacity,
                                maxZoom: wfsMinZoom,
                                maxNativeZoom: wfsMinZoom,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        } else {
                            let maxNativeZoom = LeafletMapConfig.MAX_NATIVE_ZOOM;
                            if (layer['layer'] === 'A_SECOUL_point') {
                                maxNativeZoom = LeafletMapConfig.ZOOM_MAX;
                            }
                            layerOptions = {
                                format: "image/png",
                                layers: layerKey,
                                transparent: true,
                                opacity: opacity,
                                maxZoom: LeafletMapConfig.ZOOM_MAX,
                                maxNativeZoom: maxNativeZoom,
                            };

                            tileLayer = L.tileLayer.wms(layerUrl, layerOptions);
                        }

                        if (selected) {
                            defaultSelectedLayers.push(tileLayer);
                        }
                        if ('COM' === filterMo) {
                            comLayers.push(tileLayer);
                        }
                        if ('INTERCOM' === filterMo) {
                            intercomLayers.push(tileLayer);
                        }
                        if ('EU' === filterReseau) {
                            reseauEuLayers.push(tileLayer);
                        }
                        if ('EP' === filterReseau) {
                            reseauEpLayers.push(tileLayer);
                        }
                        if ('UN' === filterReseau) {
                            reseauUnLayers.push(tileLayer);
                        }
                        if ('INCONNU' === filterReseau) {
                            reseauInconnuLayers.push(tileLayer);
                        }
                        layerObjects[group][i] = {
                            layer: tileLayer,
                            filterTypo: filterTypo,
                            filterMo: filterMo,
                            filterReseau: filterReseau,
                            label: name,
                            subGroup: subGroup,
                            hasSubGroup: hasSubGroup
                        };
                        return true;
                    });

                    setComLayersGroup(L.layerGroup(comLayers));
                    setIntercomLayersGroup(L.layerGroup(intercomLayers));
                    setReseauEuGroup(L.layerGroup(reseauEuLayers));
                    setReseauEpGroup(L.layerGroup(reseauEpLayers));
                    setReseauUnGroup(L.layerGroup(reseauUnLayers));
                    setReseauInconnuGroup(L.layerGroup(reseauInconnuLayers));

                    let overlaysTree = preprareOverlayTree(layerObjects);

                    if (defaultSelectedLayers) {
                        const defaultSelectedLayersGroup = L.layerGroup(defaultSelectedLayers)
                        try {
                            defaultSelectedLayersGroup.addTo(map);
                        } catch (e) {
                            // console.log(e);
                        }
                        L.control.layers.tree(baseTree, overlaysTree, treeOptions).addTo(map);
                    }
                    // console.log('before refresh')
                    // refreshActiveLayers();
                })
                .catch()
                {
                    // getMapPreferences error
                }
            }

            map.on('overlayadd', function() {
                // setRefreshing(true);
                // console.log('before refresh')
                // console.log('overlayadd')
                // // refreshActiveLayers(true);
                //
                // setTimeout(() => {
                //     console.log(refreshing)
                //     if (!refreshing) {
                //         setRefreshing(false);
                //         refreshActiveLayers(true);
                //     }
                // }, 1000);
            });
            map.on('overlayremove', function() {
                // console.log('overlayremove')
                // console.log('overlayremove')
                // setRefreshing(true);
                // console.log('before refresh')
                // // refreshActiveLayers(true);
                // setTimeout(() => {
                //     console.log(refreshing)
                //     if (!refreshing) {
                //         setRefreshing(false);
                //         refreshActiveLayers(true);
                //     }
                // }, 1000);
            });
            map.on('baselayerchange', function() {
                Object.keys(map._layers).map((key) => {
                    const treeName = map._layers[key]?._layersTreeName;
                    if (undefined !== treeName) {
                        console.log('setmapinfo')
                        setMapInfo({
                            baseLayer: treeName,
                        });
                        setMapPreferences({ baseMap: treeName });
                        return true;
                    } else {
                        return false;
                    }
                });
            });
            if (comLayers) {
                setComLayersGroup(L.layerGroup(comLayers));
            }

            if (intercomLayers) {
                setIntercomLayersGroup(L.layerGroup(intercomLayers));
            }

            if (reseauInconnuGroup) {
                setReseauInconnuGroup(L.layerGroup(reseauInconnuGroup));
            }

            if (reseauEpGroup) {
                setReseauEpGroup(L.layerGroup(reseauEpGroup));
            }

            if (reseauEuGroup) {
                setReseauEuGroup(L.layerGroup(reseauEuGroup));
            }

            if (reseauEpGroup) {
                setReseauUnGroup(L.layerGroup(reseauUnGroup));
            }
            // console.log('before refresh')
            // refreshActiveLayers();
            doShowHighlight(mapInfo.highlight);
        }
    }, [map]);

    function goHome() {
        map.flyTo(props.center, LeafletMapConfig.ZOOM_DEFAULT);
    }

    function getRegardPlt(){
        const url = `/api/${contractId}/plt/ident`;
        return Request.get(url, false)
            .then((response: {data: any}) => {
                return response.data
            })
        ;
    }

    function onChangeNetworkFilter(filter: any, active: any) {
        console.log(filter)
        console.log(active)
        // show all com/intercom layers by toggle network filter
        if ('com' === filter) {
            setComActive(active);
            if (active) {
                comLayersGroup.addTo(map);
            } else {
                map.removeLayer(comLayersGroup);
            }
            if (intercomActive) {
                intercomLayersGroup.addTo(map);
            } else {
                map.removeLayer(intercomLayersGroup);
            }
        }
        if ('intercom' === filter) {
            setIntercomActive(active);
            if (active) {
                intercomLayersGroup.addTo(map);
            } else {
                map.removeLayer(intercomLayersGroup);
            }
            if (comActive) {
                comLayersGroup.addTo(map);
            } else {
                map.removeLayer(comLayersGroup);
            }
        }
        if ('eu' === filter) {
            setReseauEuActive(active);
            if (active) {
                reseauEuGroup.addTo(map);
            } else {
                map.removeLayer(reseauEuGroup);
            }
        }
        if ('ep' === filter) {
            setReseauEpActive(active);
            if (active) {
                reseauEpGroup.addTo(map);
            } else {
                map.removeLayer(reseauEpGroup);
            }
        }
        if ('un' === filter) {
            setReseauUnActive(active);
            if (active) {
                reseauUnGroup.addTo(map);
            } else {
                map.removeLayer(reseauUnGroup);
            }
        }
        if ('inconnu' === filter) {
            setReseauIncunnuActive(active);
            if (active) {
                reseauInconnuGroup.addTo(map);
            } else {
                map.removeLayer(reseauInconnuGroup);
            }
        }
    }

    return (
        <>
        <MapContainer
            {...zooms}
            {...rest}
            center={center}
            whenCreated={setMap}
        >
            {showHighlight && !highlightPoint && geojson &&
                <GeoJSON
                    key={highlightId}
                    data={geojson}
                    style={highlightLineStyle}
                />
            }
            {showHighlight && highlightPoint && highlightCircle &&
                <Marker
                    position={highlightPoint}
                    icon={circleIcon}
                    key={'highlightPoint'}
                />
            }
            {showHighlight && highlightPoint && !highlightCircle &&
                <Marker
                    position={highlightPoint}
                    icon={crossIcon}
                    key={'highlightCircle'}
                />
            }
            <Control position="topright">
                <div className="leaflet-bar">
                    <button onClick={() => goHome()}>
                        <SvgHome/>
                    </button>
                </div>
            </Control>
            <Control position="topright">
                <div className="leaflet-bar">
                    <button onClick={() => setShowLegend(!showLegend)}>
                        <SvgQuestion/>
                    </button>
                </div>
                <div className={`map-legend ${showLegend ? "show" : ""}`}>
                    <div className="box-shadow">
                        <span className="legend-title" key={"title"}>{t('map_legend')}</span>
                        <ul className="list-group" key={"list"}>
                            {activePatrimoineLayers.map((layerId: string) => {
                                const legendUrl = geoserverLegendUrl + layerId;
                                return <>
                                    <li className="list-group-item" key={layerId}>
                                        <p className="legend-item">{t(`indices.patrimoine.${layerId.toLowerCase()}`)}</p>
                                        <img src={legendUrl} alt={layerId} />
                                    </li>
                                </>
                            })}
                            {
                                conformiteLayers &&
                                <li className="list-group-item" key={"conformite"}>
                                    <p className="legend-item">{t('indices.conformite')}</p>
                                    <img src={LegendConform} alt={'conformite-legend'} height={'70px'}/>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </Control>
            {
                moFilter &&
                <Control position="topright">
                    <div className="leaflet-bar">
                        <button onClick={() => setShowFilterMo(!showFilterMo)}>
                            <img src={NetworkIcon} alt={"network icon"} height={30} />
                        </button>
                    </div>
                    <div className={`network-filter ${showFilterMo ? "show" : ""}`}>
                        <div className="box-shadow">
                            <span className="network-title">{t('mo')}</span>
                            <label className="leaflet-layerstree-header-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={comActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('com', !comActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('com')}</span>
                            </label>
                            <label className="leaflet-layerstree-header-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={intercomActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('intercom', !intercomActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('intercom')}</span>
                            </label>
                        </div>
                        <div className="box-shadow">
                            <span className="network-title">{t('networks')}</span>
                            <label className="leaflet-layerstree-header-label network-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={reseauEuActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('eu', !reseauEuActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('eu')}</span>
                            </label>
                            <label className="leaflet-layerstree-header-label network-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={reseauEpActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('ep', !reseauEpActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('ep')}</span>
                            </label>
                            <label className="leaflet-layerstree-header-label network-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={reseauUnActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('un', !reseauUnActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('un')}</span>
                            </label>
                            <label className="leaflet-layerstree-header-label network-label">
                                <input type="checkbox" className="leaflet-control-layers-selector" checked={reseauInconnuActive}/>
                                <span className="leaflet-control-layers-checkbox-toggle" onClick={() => onChangeNetworkFilter('inconnu', !reseauInconnuActive)}> </span>
                                <span className="leaflet-layerstree-header-name">{t('inconnu')}</span>
                            </label>
                        </div>
                    </div>
                </Control>
            }
            <Control position="topright">
                <div className="leaflet-bar">
                    <button onClick={() => setLocateClicked(locateClicked+1)}>
                        <img src={MarkerIcon} alt={"marker icon"} height={30} />
                    </button>
                </div>
            </Control>
            <ScaleControl position={"bottomleft"} imperial={false} />
            <LocateComponent locateClicked={locateClicked} setLocateClicked={setLocateClicked}/>
            <GetBounds
                setLatLng={setLatLng}
                setBounds={setBounds}
                setBoundsNE={setBoundsNE}
                setBoundsSW={setBoundsSW}
                setZoom={setZoom}
                setMapInfo={setMapInfo}
            />
            <LeafletRuler />
            <ResizeMap setMapInfo={setMapInfo} mapInfo={mapInfo} refreshing={refreshing}/>
        </MapContainer>
    </>
    );
};

const mapStateToProps = (state: any) => {
  return {
    mapInfo: state.map,
    contract: state.contract.currentContract,
    search: state.search,
  };
};

const mapDispatchToProps = {
  setMapCoordinate: MapActions.setMapCoordinate,
  setMapInfo: MapActions.setMapInfo,
  showDetail: SearchActions.showDetail,
  showDetailControl: SearchActions.showDetailControl,
  getMapPreferences: UserActions.getMapPreferences,
  showReport: ReportActions.showReport,
  hideReport: ReportActions.hideReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeafletMapComponent);

import React, {ReactElement, useState} from "react";
import {Accordion, Card, Table} from "react-bootstrap";
import "./detail.scss";
import {SzIcon} from "@suezenv/react-theme-components";
import {useTranslation} from "react-i18next";

interface IDetailsTable {
  values: string[][];
}

const DetailsTableAccordionVisit = (props: IDetailsTable): ReactElement => {
    const [detailOpen, setDetailOpen] = useState<boolean>(true);
    const [collecOpen, setCollecOpen] = useState<boolean>(true);
    const [regardOpen, setRegardOpen] = useState<boolean>(true);
    const [branchOpen, setBranchOpen] = useState<boolean>(true);
    const [regbraOpen, setRegbraOpen] = useState<boolean>(true);
    const { t } = useTranslation();
    const visitRel = ['Liste des tronçons','Liste des branchements','Liste des regards de visite','Liste des regards de branchement'];
    let haveCollec = false;
    let haveBranch = false;
    let haveRegard = false;
    let haveRegbra = false;
    {props.values?.map((property) => {
        if (property && 'Liste des branchements' === property[0]) {
            haveBranch = true;
        }
        if (property && 'Liste des tronçons' === property[0]) {
            haveCollec = true;
        }
        if (property && 'Liste des regards de visite' === property[0]) {
            haveRegard = true;
        }
        if (property && 'Liste des regards de branchement' === property[0]) {
            haveRegbra = true;
        }
    })}
    return (
    <>
        <Accordion defaultActiveKey="0">
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    onClick={() => setDetailOpen(!detailOpen)}
                >
                    {t("asset:synthesis.details")}
                    {detailOpen ?
                        <SzIcon variant="bold" icon="arrow-up-1" /> :
                        <SzIcon variant="bold" icon="arrow-down-1" />
                    }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Table size="sm">
                            <tbody>
                            {props.values?.map((property, key) => {
                                if (property && -1 === visitRel.indexOf(property[0])) {
                                    return (
                                      <tr key={key}>
                                          <td className="gaia-td-align-right gaia-td-align-center cell-width">
                                              {property[0]} :
                                          </td>
                                          <td className="gaia-td-align-left gaia-td-align-center cell-width">
                                              {property[1]}
                                          </td>
                                      </tr>
                                    );
                                }
                            })}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
        {haveBranch &&
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setBranchOpen(!branchOpen)}
                    >
                        {t("asset:synthesis.listBranch")}
                        {detailOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table size="sm">
                                <tbody>
                                {props.values?.map((property, key) => {
                                    if (property && 'Liste des branchements' === property[0]) {
                                        return (
                                            <tr key={key}>
                                                <td className="gaia-td-align-right gaia-td-align-center cell-width">
                                                    {property[0]} :
                                                </td>
                                                <td className="gaia-td-align-left gaia-td-align-center cell-width">
                                                    {property[1]}
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        }
        {haveCollec &&
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setCollecOpen(!collecOpen)}
                    >
                        {t("asset:synthesis.listCollec")}
                        {detailOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table size="sm">
                                <tbody>
                                {props.values?.map((property, key) => {
                                    if (property && 'Liste des tronçons' === property[0]) {
                                        return (
                                            <tr key={key}>
                                                <td className="gaia-td-align-right gaia-td-align-center cell-width">
                                                    {property[0]} :
                                                </td>
                                                <td className="gaia-td-align-left gaia-td-align-center cell-width">
                                                    {property[1]}
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        }
        {haveRegard &&
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setRegardOpen(!regardOpen)}
                    >
                        {t("asset:synthesis.listRegard")}
                        {detailOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table size="sm">
                                <tbody>
                                {props.values?.map((property, key) => {
                                    if (property && 'Liste des regards de visite' === property[0]) {
                                        return (
                                            <tr key={key}>
                                                <td className="gaia-td-align-right gaia-td-align-center cell-width">
                                                    {property[0]} :
                                                </td>
                                                <td className="gaia-td-align-left gaia-td-align-center cell-width">
                                                    {property[1]}
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        }
        {haveRegbra &&
            <Accordion defaultActiveKey="0">
                <Card>
                    <Accordion.Toggle
                        as={Card.Header}
                        eventKey="0"
                        onClick={() => setRegbraOpen(!regbraOpen)}
                    >
                        {t("asset:synthesis.listRegbra")}
                        {detailOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <Table size="sm">
                                <tbody>
                                {props.values?.map((property, key) => {
                                    if (property && 'Liste des regards de branchement' === property[0]) {
                                        return (
                                            <tr key={key}>
                                                <td className="gaia-td-align-right gaia-td-align-center cell-width">
                                                    {property[0]} :
                                                </td>
                                                <td className="gaia-td-align-left gaia-td-align-center cell-width">
                                                    {property[1]}
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        }

    </>
  );
};

export default DetailsTableAccordionVisit;

import { categories } from "../../../config/constants";
import { SEARCH } from "../constants";
import { ISearch, ISearchData, SearchActionTypes } from "../types";

const defaultSearchResult: ISearchData[] = [
  { category: categories.GEO, data: { candidates: [] } },
  { category: categories.DOC, data: { documents: [], selectedFacets: {} } },
  { category: categories.ASSET, data: { objects: [], selectedIndices: [], selectedFacets: {} } },
];
const initialState: ISearch = {
  detail: {
    shownDetailAnchor: "",
    shownDetailCategory: "",
    shownDetailId: "",
    shownDetailItem: "",
    shownEquipementId: "",
    defaultTab: "",
  },
  flag: {
    loading: false,
    showResultDetail: false,
    showSearchResult: false,
    showSmartshape: false,
    showSuggestions: false,
    userInputTooShort: false,
  },
  history: {
    currentPage: 0,
    results: 0,
    searches: [],
    suggestionSearches: [],
  },
  isDisplayed: false,
  isMapDisplayedFullscreen: false,
  maxResult: 99,
  searchData: defaultSearchResult,
  suggestions: {
    activeSuggestion: 0,
    filteredSuggestions: [{ text: null, extraToken: null }],
  },
  userInput: "",
};

const searchReducers = (state = initialState, action: SearchActionTypes): ISearch => {
  switch (action.type) {
    case SEARCH.RESULT:
      const searchResult = defaultSearchResult.map((res: ISearchData) => {
        const dataUpdate = action.payload.searchData.find((payload: any) => {
          if (payload.category === res.category) {
            return payload.data;
          }
          return false;
        });
        return dataUpdate ? dataUpdate : res;
      });
      return {
        ...state,
        flag: { ...state.flag, showResultDetail: false, showSearchResult: true },
        maxResult: action.payload.maxResult,
        searchData: searchResult,
        userInput: action.payload.userInput,
      };
    case SEARCH.DETAIL:
      return {
        ...state,
        detail: {
          shownDetailAnchor: action.payload.detail.shownDetailAnchor,
          shownDetailCategory: action.payload.detail.shownDetailCategory,
          shownDetailId: action.payload.detail.shownDetailId,
          shownDetailItem: action.payload.detail.shownDetailItem,
          defaultTab: action.payload.detail.defaultTab,
        },
        flag: {
          ...state.flag,
          showResultDetail: true,
          showSearchResult: false,
          showSmartshape: action.payload.flag.showSmartshape,
        },
        searchData: action.payload.searchData,
      };
    case SEARCH.DETAIL_OBJECT:
      return {
        ...state,
        flag: {
          ...state.flag,
          showResultDetail: true,
        },
      };
    case SEARCH.DETAIL_TAB:
      return {
        ...state,
        detail: {
          ...state.detail,
          defaultTab: action.payload.detail.defaultTab,
        },
      };
    case SEARCH.SUGGEST:
      return {
        ...state,
        flag: {
          ...state.flag,
          loading: action.payload.flag.loading,
          showSuggestions: action.payload.flag.showSuggestions,
        },
        suggestions: {
          activeSuggestion: action.payload.suggestions.activeSuggestion,
          filteredSuggestions: action.payload.suggestions.filteredSuggestions,
        },
        userInput: action.payload.userInput,
      };
    case SEARCH.AUTO_COMPLETE:
      return {
        ...state,
        flag: {
          ...state.flag,
          loading: action.payload.flag.loading,
          showSuggestions: action.payload.flag.showSuggestions,
        },
        suggestions: {
          ...state.suggestions,
          activeSuggestion: action.payload.suggestions.activeSuggestion,
        },
      };
    case SEARCH.INPUT_VALUE:
      return (state = { ...state, userInput: action.payload });
    case SEARCH.LOADING:
      return {
        ...state,
        flag: { ...state.flag, loading: action.payload.flag.loading },
      };
    case SEARCH.BACK_TO_SEARCH:
      return {
        ...state,
        flag: { ...state.flag, showResultDetail: false, showSearchResult: true },
        detail: { ...state.detail, shownEquipementId: ""},
      };
    case SEARCH.SEARCH_EMPTY_ERROR:
      return { ...state, flag: { ...state.flag, userInputTooShort: action.payload } };
    case SEARCH.CLOSE_ALL:
      return {...state, flag: { ...state.flag, showResultDetail: false, showSearchResult: false}};
    case SEARCH.SEARCH_INIT:
      return { ...initialState, history: {...state.history}};
    case SEARCH.GET_HISTORY:
      return {...state,
        history: {
          currentPage: action.payload.currentPage,
          results: action.payload.results,
          searches: action.payload.searches,
          suggestionSearches: state.history.suggestionSearches,
      }};
    case SEARCH.GET_SUGGESTION_HISTORY:
      return {
        ...state,
        history: {
          searches: state.history.searches,
          suggestionSearches: action.payload.suggestionSearches,
        },
      };
    case SEARCH.DISPLAY_SMARTSHAPE_FULLSCREEN:
      return {...state, isDisplayed: action.payload};
    case SEARCH.DISPLAY_EQUIPEMENT_FROM_SMARTSHAPE:
      return {
        ...state,
        flag: {
          ...state.flag,
          showResultDetail: true,
          showSearchResult: false,
        },
        detail: {
          ...state.detail,
          shownEquipementId: action.payload,
        },
      };
    case SEARCH.DISPLAY_MAP_FULLSCREEN:
      return {...state, isMapDisplayedFullscreen: action.payload};
    default:
      return state;
  }
};

export default searchReducers;

import i18next from "i18next";
import React, { ReactElement } from "react";
import { IAssetDetailsTab, IAssetDetailsTabProperty } from "../../config/asset.interfaces";
import "./detail.scss";
import DetailsTable from "./detailsTable";
import DetailsTableAccordion from "./detailsTableAccordion";
import DetailsTableAccordionVisit from "./detailsTableAccordionVisit";

interface ISynthesis {
  item: any;
  getDisplayedValue: (item: any, property: IAssetDetailsTabProperty) => string;
  tabLabel: string;
  mapping?: any;
}

const Synthesis = (props: ISynthesis): ReactElement => {
  const {mapping, item, tabLabel, getDisplayedValue} = props;
  const indexName = item["index:name_ori"];
  const contractId = item["sig:contractId"];
  const map = mapping[indexName].find((item: IAssetDetailsTab) => item.label === tabLabel);
  if (!map || typeof map === "undefined") {
    return (<div>{i18next.t("no-data")}</div>);
  }
  const properties = map.properties;
  const values = properties.map((property: IAssetDetailsTabProperty) => {
    const displayedValue = getDisplayedValue(item, property);
    return displayedValue ? [
      i18next.t(`asset:${tabLabel}.${property.label}`),
      displayedValue,
    ] : false;
  });

  if (item["asset:plt"] !== undefined && contractId == "420d59de-c3ed-11ed-a0a0-0242ad15150b") {
    return (
        <DetailsTableAccordion values={values}/>
    );
  } else if (
    (
      item["asset:listBranch"] !== undefined ||
      item["asset:listCollec"] !== undefined ||
      item["asset:listRegard"] !== undefined ||
      item["asset:listRegbra"] !== undefined
    )
    && contractId == "618ac3b4-116f-11ec-89f4-0242ac15151e") {
    return (
        <DetailsTableAccordionVisit values={values}/>
    );
  } else {
    return (
        <DetailsTable values={values}/>
    );
  }
};

export default Synthesis;
